.App {
  width: 100vw;
  height:100vh;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: black;
}

button:focus, input:focus, select:focus, textarea:focus {
  outline: none;
}