@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: "Quicksand", serif;
  color: #DE66D4;
}
button:focus, input:focus, select:focus, textarea:focus {
  outline: none;
}
.signin{
  display: flex;
  margin-left: 25px;
  flex-wrap: wrap;
  align-items: center;
}
.navbar {
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background-color: black;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 10px 0;
}

.tapInfoContainer{
  display: flex;
  justify-content: space-around;
  gap: 8px;
  padding: 10px;
}
.gameArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

#circle {
  border-radius: 50%;
  background-color: #DE66D4;
  transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
  border: none;
  outline: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square  {
  width: 165px;
  height: 50px;
  padding-top: 6px;
  padding-left: 12px;
  background-color: #212121;
  text-align: left;
  justify-content: left;
  align-items: center;
  border-radius: 15px
}

.navbar a {
  text-decoration: none;
  padding-top: 30px;
}
.content {
  min-height: 350px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  top: 30%;
}

.navbarButtons{
  font-weight: bold;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 30px;
  color: grey;
  border-radius: 8px;
}

.navbarButtons.active {
  color: #DE66D4;
}

.wallet-link{
  background-image: url('/src/images/wallet.png');
}.wallet-link.active{
  background-image: url('/src/images/WalletActive.png');
}
.boost-link{
  background-image: url('/src/images/boost.png');
}.boost-link.active{
  background-image: url('/src/images/BoostActive.png');
}
.play-link{
  background-image: url('/src/images/playbutton.png');
}.play-link.active{
  background-image: url('/src/images/PlayButtonActive.png');
}
.hotdeals-link{
  background-image: url('/src/images/hotdeals.png');
}.hotdeals-link.active{
  background-image: url('/src/images/HotDealsActive.png');
}
.profile-link{
  background-image: url('/src/images/profile.png');
}.profile-link.active{
  background-image: url('/src/images/ProfileActive.png');
}
.boostIcon{
  background-repeat: no-repeat;
  height: 36px;
  width: 36px;
  background-size: cover;
}
.timeSlowIcon{
  background-image: url('/src/images/TimeSlowDeactive.png');
}
.areaActiveIcon{
  background-image: url('/src/images/AreaExpandDeactive.png');
}
.multiplierActiveIcon{
  background-image: url('/src/images/MultiplierDeactive.png');
}
.timeSlowIconA{
  background-image: url('/src/images/TimeSlowActive.png');
}
.areaActiveIconA{
  background-image: url('/src/images/AreaExpandActive.png');
}
.multiplierActiveIconA{
  background-image: url('/src/images/MultiplierActive.png');
}
.profileBar{
  position: fixed;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto auto;
  grid-template-areas: "first-column-left first-column-right";
  width: 100%;
  padding-top: 16px;
  padding-left: 16px;
}
.profile-pic{
   width: 50px;
   height: 50px;
   background-size: 50px;
   background-color: #2F2F2F;
   background-image: url('/src/images/ProfilePic.png');
   grid-area: first-column-left;
   border-radius: 50%;
 }

.membership{
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px;
}

.membershipPage{
  height: 90vh;
  background-position: center;
  display: flex;
  justify-items: center;
  align-items: center;
  background-image: url('/src/images/PremiumMembership.png');
  background-repeat: no-repeat;
}
.membershipPageOverlay{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.mBasic{
  background-image: url('/src/images/BasicMembership.png');
}
.mPremium{
  background-image: url('/src/images/PremiumMembership.png');
}
.profileDetails{
    position: fixed;
    display: grid;
    place-items: start;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas: "first-row-left first-row-right"
    "second-row second-row"
    "third-row third-row";
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
    width: 100%;
    padding-left: 26px;
}
.rows-container {
  margin-left: 10px;
  width: 415px;
  height: 114px;
  grid-area: first-row-right;
}

.profile-detail-style{
  display: flex;
  justify-content: left;
  height: 50px;
  text-align: left;
  font-size: 12px;
}
.profile-edit-verified{
  display: grid;
  justify-items: center;
  width: 90px;
  height: 50px;
  font-size: 12px;
}
.profile-pic-big{
  width: 114px;
  height: 114px;
  grid-area: first-row-left;
  background-size: 114px;
  background-color: #2F2F2F;
  background-image: url('/src/images/ProfilePic.png');
  border-radius: 15%;
}
.edit-button {
  margin-left: 5px;
  background-image: url('/src/images/EditBadge.png');
  width: 44px;
  height: 22px;
  border: none;
  border-radius: 5px;
  color: grey;
  cursor: pointer;
}
.verified {
  margin-left: 5px;
  background-image: url('/src/images/VerifiedBadge.png');
  color: #00A811;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  width: 44px;
  height: 16px;
  border: none;
  border-radius: 5px;
}
.wallet-address{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 220px;
  height: 24px;
  background-color: #333333;
  border-radius: 5px;
}
.wallet-copy{
  border: none;
  margin-right: 5px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/src/images/Copy.png');
  height: 20px;
  width: 20px;
}

.scroll-container-horizontal {
  padding-top: 10px;
  scrollbar-width: none;
  margin-left: 10px;
  width: 83vw;
  height: 20vh;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  justify-content: left;
  align-items: center;
  display: flex;
}
.glow-path {
  filter: drop-shadow(0 0 3px rgba(40, 144, 255, 1)); /* Blue glow */
}

.indicator-container {
  text-align: center;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #DE66D4;
}

.item {
  flex: 0 0 80vw;
  margin-left: 5px;
  height: 17vh;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 4%;
  border: 5px solid mediumpurple;
  background-size: cover;
  background-position: center;
}
.item-overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Makes sure the overlay is above the content */
}
.item-container{
  padding-top: 10px;
  height: 100%;
  width: 80%;
  display: grid;
  place-items: start;
  grid-template-rows: auto auto;
  grid-template-areas: "first-row"
    "second-row";
  text-align: left;
  white-space: normal;
  text-overflow: clip;
}
.item-1{
  background-image: url("/src/images/BG1.png");
}
.item-2{
  background-image: url("/src/images/BG2.png");
}.item-3{
  background-image: url("/src/images/BG3.png");
}.item-4{
  background-image: url("/src/images/BG4.png");
}

.badges-style{
  display: flex;
  justify-content: left;
  height: 50px;
  text-align: left;
  font-size: 12px;
  padding-top: 5px;
}
.badges{
  margin-right: 5px;
  background-image: url('/src/images/Badge.png');
  color: #F8D21E;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  width: 50px;
  height: 16px;
  border: none;
  border-radius: 5px;
}

.x-boost-text{
  grid-area: second-row;
  margin-top: 8px;
  margin-bottom: 5px;
  margin-left: 28px;
  width: 320px;
  height: 24px;
  background-color: #333333;
  border-radius: 5px;
}

.vertical-scroll-profile{
  scrollbar-width: none;
  width:87vw;
  justify-content: center;
  align-items: center;
}
.item2 {
  margin-bottom: 10px;
  color: dimgrey;
  flex: 0 0 80vw;
  margin-left: 5px;
  height: 120px;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 4%;
  border: 10px solid #212121;
  background-color: black;
}
.scroll-container:active {
  cursor: grabbing;  /* Changes cursor during dragging */
}

